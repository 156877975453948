.main{
  padding: 0 20px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background: white;
  /* border-bottom: 1px solid #ddd; */
  
  
  
}

.date-selector {
  position: relative;
  
  
}

.calendar-icon {
  cursor: pointer;
  margin-left: 28%;
 
  
}

.date-input {
  position: absolute;
  top: 90%;
  left: 0;
}

.view-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
  
}

.view-button {
  margin: 0 4px;
  padding: 7px 15px 7px 15px;
  
  cursor: pointer;
  /* border: none; */
  background: #ddd;
  width: 45%;
  border-radius: 30px;
}

.view-toggle {
  .view-button.active {
  background: #08386c;
  color: white;
  /* border-radius: 4px; */
}
}


.add-event-icon {
  cursor: pointer;
  margin-left: 2%;
  background-color: #5a728d;
  color: #f5f5f5;
  border-radius: 20%;
  height: 26px;
  width: 40px;
  &:hover{
    background-color: #08386c;
  }
  
}

.calendar-body {
  padding: 8px;
  /* background-color: rgb(241, 245, 244); */
  background-color: hsla(197, 61%, 91%, 0.612);
  border-radius: 5px;
}

.day-view {
  display: flex;
  flex-direction: column;
}

.all-day-events, .regular-events {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  
}

.task, .all-day-task {
  margin: 2px;
  padding: 5px;
  /* background: #3f6995; */
  background-color:  #095D7E;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.scrollable-events {
  display: flex;
  flex-direction: column;
   max-height: calc(100vh - 200px); /* Adjust the height to fit your page */
  overflow-y: auto;
  
}
.scrollable-events::-webkit-scrollbar {
  width: 8px;


}
.scrollable-events::-webkit-scrollbar-thumb {
  /* background: #999b9d; */
  border-radius: 4px;

}



.time-slot {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  /* box-shadow:0px 0px 1.5px 0 #485b71; */
  border-radius: 5px;
  /* border-left: 5px solid transparent; */
  cursor: pointer;
  /* background-color: #ddddddf9; */
  /* background-color: #b9d3e2; */
  /* background-color: #c4dcea; */
  /* background-color: #bbd3e0; */
  background-color: #b4cbd198;
  width: 100%;
}

.time-slot:hover {
  border-left: 5px solid #0b3968;
}

.time-slot span {
  width: 50px;
  flex-shrink: 0;
}

.tasks {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 100%;
}

.month-view {
  display: flex;
  flex-direction: column;

}

.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  
}

.nav-icon {
  cursor: pointer;
}


.month-view-table th, .month-view-table td {
  border: 1px solid #ddd;
  
  text-align: center;
  vertical-align: top;
  padding: 5px;
  /* box-shadow: 2px 0 29px 0 #0b3968; */
  /* border-radius: 5px; */
  

}

.title{
  font-size: 27px;
}



.day-slot {
  position: relative;
  height: 80px;
  
  
}

.day-slot.today {
  background: #10457d;
  color: white;
  
}

.empty-day {
  background: #b3b3b32f;
  
}

.day-number {
  font-weight: bold;
  
}

.all-day-events, .regular-events {
  /* position: absolute; */
  width: 100%;
  top: 20px;
  left: 5px;
  right: 5px;
  
}
.current-time {
  position: relative;
  
}

.current-time::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 3px solid rgb(58, 77, 134);
  transform: translateY(-50%);
  box-shadow: 4px 0 30px 1.2px #0b3968;
}



@media only screen
and (min-device-width: 490px) 
  and (max-device-width: 530px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .view-button {
      /* margin: 0 5px; */
      padding: 4% 8% 4% 8%;
      cursor: pointer;
      /* border: none; */
      background: #ddd;
      width: 90%;
      /* border-radius: 30px; */
    }
    .title{
      font-size: 27px;
      margin-top: 3%;
    }
    .date-selector{
      margin: none;
    }
    .calendar-icon{
      margin-left: 4%;
      margin-bottom: 4%;
    }
   
.add-event-icon {
  cursor: pointer;
  margin-left: 1%;
  background-color: #5a728d;
  padding: 1%;
  border-radius: 20%;
  width: 23%;
  &:hover{
    background-color: #08386c;
  }
  
}
.view-toggle{
  display: flex;
  gap:8%;
  align-items: center;
  padding-right: 13%;
  background: #f5f5f5;
  

}


  
}


