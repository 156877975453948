html{
  font-family: 'Open Sans';
}
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
