/* Landing-page */



.landingpage{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto !important;
}

.landingpage .first-column{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height : 90%;
  /* overflow: hidden; */
}

.landingpage .column2heder{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8vw;
  width: 100%;
  height : 15%;
  color: #000;
  font-size: 3vw;
  font-weight: 600;
  overflow: hidden;
}

.landingpage .header{
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  top: 5vh;
  left: 5vw;
  /* grid-area: header; */
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-end; */
  font-weight: 700;
  font-size: 3vw;
  color: #347995;
  letter-spacing: 1px;
  /* padding-left: 4vw; */
  /* width: 100%;
  height: 100%; */
  overflow: hidden;
}

.landingpage .header img{
  height: 5vw;
}

.landingpage .maincontent{
  /* grid-area: maincontent; */
  position: absolute;
  top: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 80%;
  overflow: hidden;
}

.landingpage .maincontent .row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landingpage .maincontent form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landingpage .maincontent .row2{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: #45a049; */
  overflow: hidden;
}

.landingpage .maincontent .row .tagline{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  font-weight: 700;
  font-size: 2vw;
  /* color: #256e6b; */
  width: 100%;
  height: 80%;
  overflow: hidden;
  background-color: #cde5e8;
}

.landingpage .maincontent .row .tagline button{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 650;
  font-size: 1.8vw;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #347995;
  width: 50%;
  height: 16%;
  border-radius: 25px;
  border: none;
  overflow: hidden;
}

.landingpage .maincontent .row2 img{
  width: 85%;
  height: 100%;
  /* box-shadow: 0 0 100px #cde5e8; */
}

.landingpage .row2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  height: 150%;
  width: 1.5em;
  background-color: aliceblue;
  opacity: 0.3;
  animation: glider 2s infinite linear;
  animation-delay: 0.05s;
}

.landingpage .row2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  height: 150%;
  width: 1em;
  background-color: aliceblue;
  opacity: 0.2;
  animation: glider 2s infinite linear;
}

@keyframes glider {
  0% {
    transform: rotate(45deg) translate(-140px, -140px);
  }

  100% {
    transform: rotate(45deg) translate(220px, -40px);
  }
}

.landingpage .page1{
  position: absolute;
  top: 3vh;
  right: 3vw;
  width: 25%;
  height: 35%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.landingpage .page2{
  position: absolute;
  bottom: 4vh;
  right: 3vw;
  width: 25%;
  height: 35%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}



.landingpage .cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 85%;
  padding: 2vw;
  background-color: #ffffff;
  overflow: hidden;
}

.landingpage .cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 30%;
  border-radius: 15px;
  padding: 2vw;
  color: #001219;
  background-color: #8db4b8;
  cursor: pointer;
  transition: 400ms;
  overflow: hidden;
}

.landingpage .cards .card p.tip {
  font-size: 1.6vw;
  font-weight: 700;
}

.landingpage .cards .card p.second-text {
  font-size: 1vw;
}

.landingpage .cards .card:hover {
  background-color: #94D2BD;
  transform: scale(1.1, 1.1);
}

.landingpage .cards:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}


.footer {
  position: relative;
  width: 100%;
  background: #9dd6c2;
  height: 30%;
  padding: 2vh;
  margin-top: 10%;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  width: 100%;
  height: 30%;
  flex-direction: row;
  justify-content: center;
  margin: 1vh 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2vw;
  color: #fff;
  margin: 0 1vw;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.4vw;
  color: #fff;
  margin: 0 1vw;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  font-size: 1.2vw;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -14vh;
  left: 0;
  width: 100%;
  height: 55%;
  background: url('./images/wave.png');
  background-size: 100% 100%;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

.center-content h2{
  font-size: 2vw;font-weight: bold;
}

.contactus{
  flex-direction:column;
  height:60%;
  justify-content:flex-start;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height : 90%;
}

.contactus h1{
  width: 100%;
  text-align:center;
}

.mobheader {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
  font-size: 4vh;
  color: #347995;
  padding: 2vh 2vw;
  letter-spacing: 1px;
  overflow: hidden;
}

.mobheader img{
  width: 7vh;
  height: 7vh;
}

.landingpage .mobfirst{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 60%;
  width: 100%;
  padding: 0 4vw;
}

.landingpage .mobfirst .mobbox{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  border-radius: 30px;
  gap: 2vh;
  background-color: #94D2BD;
}

.landingpage .mobfirst .main{
  position: absolute;
  top: -20%;
  width: 80%;
  height: 60%;
}

.landingpage .mobfirst .page1{
  position: absolute;
  top: -25%;
  width: 40%;
  height: 30%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.landingpage .mobfirst .page2{
  position: absolute;
  top: 8vh;
  width: 40%;
  height: 30%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.landingpage .mobfirst h5{
  position: absolute;
  font-size: 3vh;
  top: 55%;
}

.landingpage .mobfirst button{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 650;
  font-size: 2vh;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #347995;
  width: 60%;
  height: 50%;
  border-radius: 25px;
  border: none;
  overflow: hidden;
}

.landingpage .mobfirst .mobbtns{
  position: absolute;
  bottom: 2vh;
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}

.landingpage .mobsecond{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 40%;
  width: 100%;
  padding: 0 4vw;
}

.landingpage .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 60%;
  width: 100%;
  border-radius: 20px;
  padding: 2vw;
  color: #001219;
  background-color: #94D2BD;
  cursor: pointer;
  transition: 400ms;
}

.landingpage .mobsecond h5{
  font-size: 3vh;
}

.landingpage .mobthird{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  padding: 0 4vw;
}

.landingpage .mobthird form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;
  gap: 1vh;
}

.landingpage .mobthird form .input-field{
  width: 100%;
  height: 7vh;
  padding-left: 1vh;
  margin: 0;
  font-size:2vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #095d7e;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.landingpage .mobthird form input[type='submit']{
  width: 100%;
  height: 7vh;
  font-size:2vh;
  color: #ffffff;
  border-radius: 10px;
  background-color: #095d7e;
}

/* Login-Signup-page */

.loginpage {
    display: flex;
    height: 100vh;
  }
  
  .column {
    flex: 1;
    background-color: #ffffff;
    align-items: center;
  }
  
  .column.right {
    flex: 0 0 57%;
    background-color: #cde5e8;
  }
  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
  }
  .center-content .option{
    display: flex;
    flex-direction: row;
    align-Items:center;
    margin-bottom: 1vh;
    color: var(--dark-blue, #095D7E);
    font-size: 1.5vw;
  }
  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .form-group .input-field{
    width: 100%;
    /* padding: 2vh 1vw; */
    height: 7vh;
    padding-left: 1vw;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1.5px solid #095d7e;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #ffffff;
  }
  .form-group img{
    width: 3.5vw;
    display: inline-block;
    margin: 10px;
  }
  .center-content h1{
    margin-bottom: 40px;
    font-size: 8vmin;
    font-weight:500 ;
    color: var(--dark-blue, #095D7E);
    font-weight: 600;
    line-height: 2.13125rem;
    letter-spacing: -0.02875rem;
  }
  .center-content input::placeholder {
    color: #095d7e;
    font-size: 1.3vw;
  }
  .center-content input[type=submit] {
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 8vh;
    background-color: #095d7e;
    color: white;
    border: none;
    border-radius: 0.625rem;
    cursor: pointer;
    font-size: 1.5vw;
    color: var(--WF-Base-White, #FFF);
  }
  .center-content input[type=submit]:hover {
    background-color: #45a049;
  }

  .loading-spinner {
    width: 4vw;
    height: 4vw;
    border: 8px solid #cde5e8;
    border-top: 8px solid #095d7e;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
  }

  .eye{
    font-size:1.6vw;
    color:#095d7e;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  @media screen and (max-width:500px) {

    .column{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .right{
      display: none;
    }

    .center-content{
      height: 100%;
      width: 100%;
    }

    .form-group input::placeholder{
      font-size: 3vw;
    }

    .eye{
      font-size:4vw;
    }

    .center-content input[type=submit] {
      height: 6vh;
      font-size: 2.5vw;
    }
  }


/* Doctor-profile-page */

.DocSetup-page {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 4vh 3vw 2vh 3vw;
}

.DocSetup-page input[type="button"]{
    width: 20%;
    height: 100%;
    border-radius: 0.625rem;
    background: var(--dark-blue, #095D7E);
    color: var(--WF-Base-White, #FFF);
    font-weight: 600;
    letter-spacing: -0.01125rem;
}

.DocSetup-page .button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.DocSetup-page h1{
    position: relative;
    color: var(--dark-blue, #095D7E);
    font-size: 2vw;
    font-weight: 400;
    line-height: 1.13213rem;
    letter-spacing: -0.0175rem;
}
.DocSetup-page .back{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 3vw;
  font-size: 1.5vw;
  color: var(--dark-blue, #095D7E);
  cursor: pointer;
}

.DocSetup-page .form-cointainer {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    width: 100%;
    height: 90%;
}
.DocSetup-page .form-cointainer .upload-cointainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    height: 100%;
    padding: 1vw 0;
}

.DocSetup-page .form-cointainer .upload-cointainer .upload{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--dark-blue, #095D7E);
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.13213rem;
    letter-spacing: -0.01375rem;
    width: 100%;
    height: 48%;
    border-radius: 2.8125rem;
    border: 1px solid var(--dark-blue, #095D7E);
    background: var(--light-blue, #CCECEE);
    cursor: pointer;
    overflow: hidden;
}

.DocSetup-page .form-cointainer .upload-cointainer .upload img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.DocSetup-page .form-cointainer .textbox-cointainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    height: 100%;
    padding: 1vw 0;
}
.DocSetup-page .form-cointainer .textbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1vw;
    align-items: center;
    width: 100%;
    height: 100%;
}

.DocSetup-page .form-cointainer .textbox input{
    border-radius: 0.625rem;
    border: 1px solid var(--dark-blue, #095D7E);
    background: var(--white, #FFF);
    width: 100%;
    height: 10%;
    padding-left: 1vw;
}
.DocSetup-page .form-cointainer .textbox .dualbox input{
    border-radius: 0.625rem;
    border: 1px solid var(--dark-blue, #095D7E);
    background: var(--white, #FFF);
    height: 100%;
    width: 50%;
    padding-left: 1vw;
    margin: 0;
    overflow: hidden;
}
.DocSetup-page .form-cointainer .textbox .dualbox select{
  border-radius: 0.625rem;
  border: 1px solid var(--dark-blue, #095D7E);
  background: var(--white, #FFF);
  height: 100%;
  width: 50%;
  padding-left: 1vw;
  margin: 0;
  overflow: hidden;
}
.DocSetup-page .form-cointainer .textbox textarea{
  border-radius: 0.625rem;
  border: 1px solid var(--dark-blue, #095D7E);
  background: var(--white, #FFF);
  height: 35%;
  width: 100%;
  padding-left: 1vw;
  margin: 0;
  overflow: hidden;
}
.textbox input::placeholder {
    color: var(--dark-blue, #095D7E);
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 1.13213rem;
    letter-spacing: -0.01375rem;
  }
.DocSetup-page .form-cointainer .dualbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1vw;
    width: 100%;
    height: 10%;
}

.DocSetup-page .form-cointainer .location-cointainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2vh;
    border-radius: 0.625rem;
    border: 1px solid var(--dark-blue, #095D7E);
    background: var(--white, #FFF);
    width: 90%;
    height: 60%;
    padding: 3vh 2vw;
}

.DocSetup-page .form-cointainer .location-cointainer h2{
    color: var(--dark-blue, #095D7E);
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.13213rem;
    letter-spacing: -0.01375rem;
}

.DocSetup-page .form-cointainer .location-cointainer input[type="text"]{
    border-radius: 0.625rem;
    border: 1px solid #000;
    padding: 0;
    width: 100%;
    height: 17%;
    flex-shrink: 0;
    overflow: hidden;
    padding-left: 1vw;
}

.location-cointainer input::placeholder {
    color: var(--dull-blue, #CDE5E8);
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.13213rem;
    letter-spacing: -0.00875rem;
  }

.DocSetup-page .form-cointainer .location-cointainer .locationadd{
    width: 16%;
    height: 100%;
    border-radius: 0.625rem;
    background: var(--dark-blue, #095D7E);
}

.DocSetup-page .form-cointainer .location-cointainer .button-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 16%;
    width: 100%;
}
  

/* appointment-page */

.bookappointmentpage {
    display: grid;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 0fr 3fr;
    grid-template-areas:
      'header header'
      'form calendar';
}

.bookappointmentpage .form-content{
  grid-area: form;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5vw;
  overflow: hidden;
}

.bookappointmentpage .form-content h2{
  font-size: 2vw;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.bookappointmentpage .form-content h3{
  font-size: 1vw;
  font-weight: 600;
  color: #6bacaf;
}

.bookappointmentpage .form-content h4{
  font-size: 1.5vw;
  font-weight: 700;
  color: #253d3b;
}

.bookappointmentpage .form-content form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 1.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

form::-webkit-scrollbar{
  display: none;
}

.bookappointmentpage .form-content form .firstrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-top: 1.5vh;
  overflow: hidden;
  gap: 1vw;
}

.bookappointmentpage .form-content form .firstrow .firstname{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  font-size: 1vw;
  font-weight: 600;
}
.bookappointmentpage .form-content form input,select{
  width: 100%;
  height: 55%;
  margin: 8px 0;
  padding-left: 1vw;
  box-sizing: border-box;
  border: 1px solid #6bacaf;
  border-radius: 5px;
}

.firstname input::-webkit-input-placeholder{
  /* padding-left: 1vw; */
  color: #6bacaf;
}

.bookappointmentpage .form-content form input[type="submit"]{
  width: 100%;
  height: 50%;
  margin: 8px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.5vw;
  background-color: #6bacaf;
  border-radius: 5px;
}

.bookappointmentpage .calendar-content{
  grid-area: calendar; 
  /* display: flex; */
  height: 100%;
  width: 100%;
  /* flex-direction: column;
  justify-content: flex-start;
  gap: 2vh;
  align-items: center; */
  padding-top: 2vw;
  overflow: hidden;
}

.bookappointmentpage .calendar-content .calender-container{
  /* display: flex; */
  height: 100%;
  width: 100%;
  /* flex-direction: column; */
  gap: 2vh;
  /* align-items: center; */
  font-size: 5vw;
  padding: 2vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.calender-container::-webkit-scrollbar{
  display: none;
}  

.date-picker {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #6bacaf;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: auto;
 }
 
 .month-year {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 2vw;
 }
 
 .days-of-week, .calendar-days {
  display: grid;
  font-weight: 600;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  /* font-size: 1.2vw */
 }
 .days-of-week{
  font-weight: 700;
 }
 
 .calendar-days div {
  padding: 1vw;
  text-align: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
 }

 .other-month {
  color: #ccc;
}
 
 .calendar-days div:hover {
  background-color: #38dae6;
 }
 .selected-day {
  background-color: #38dae6;
  color: #fff;
 }
 
 .current-month{
  font-weight: 700;
  font-size: 1.7vw;
 }

 .bookappointmentpage .calendar-content .clock-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  gap: 1vh;
  align-items: flex-start;
  overflow: hidden;
}
.bookappointmentpage .calendar-content .clock-container h2{
  font-size: 2vw;
  font-weight: 700;
}
.bookappointmentpage .calendar-content .clock-container .clock{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
  justify-content: space-between;
  gap: 1vw;
  align-items: center;
  overflow: hidden;
}
.bookappointmentpage .calendar-content .clock-container .clock .block{
  display: flex;
  width: 20%;
  height: 100%;
  font-size: 1.5vw;
  font-weight: 600;
  border-radius: 5px;
  color: #6bacaf;
  background-color: #e8f1f3;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


/* receptionistpage */

.receptionistpage{
    display: grid;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 0fr 3fr;
    grid-template-areas:
      'header'
      'form';
}

.receptionistpage form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4vh 8vw;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.receptionistpage h2{
  font-size: 2vw;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.receptionistpage h3{
  font-size: 1.6vw;
  font-weight: 700;
  letter-spacing: -0.01em;
}



form::-webkit-scrollbar{
  display: none;
}

.receptionistpage form .firstrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-top: 1.5vh;
  overflow: hidden;
  gap: 1vw;
}

.receptionistpage form .firstrow .firstname{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  width: 50%;
  height: 100%;
  font-size: 1vw;
  font-weight: 600;
}
.receptionistpage form input,select{
  width: 100%;
  height: 50%;
  margin: 8px 0;
  padding-left: 1vw;
  background-color: #e8eef2;
  /* box-sizing: border-box; */
  /* border: 1px solid #6bacaf; */
  border-radius: 5px;
}

.firstname input::-webkit-input-placeholder{
  /* padding-left: 1vw; */
  color: #7096b7;
}

.firstrow button{
  width: 100%;
  height: 100%;
  background-color: #6bacaf;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2vw;
  border: none;
  cursor: pointer;
}

.slotselection-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  font-size: 2vw;
  border-radius: 10px;
  border: 1px solid#7096b7;
}

.receptionistpage form input[type="submit"]{
  width: 20%;
  height: 40%;
  margin: 8px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.8vw;
  background-color: #6bacaf;
  border-radius: 5px;
}



.todo{
  width: 100%;
  height: 100%;
  
}
.form-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 20px;
  
  z-index: 1000;
}

.main-container{
 padding: 30px;
  p{
    
    font-size: 19px;
    font-weight: 500;
    color: #0A9396;
  }
}
.appointment-card {
  background: whitesmoke;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(202, 200, 200);
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgb(181, 177, 177);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  
}
.container-list{
 
  padding:10px;
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
 
 
  p{
    /* color: rgba(23, 173, 173, 0.971); */
    color: #0A9396;
    font-size: 1vw;
    font-weight: 500;
   margin: 0;
    white-space: nowrap;
    text-align: center;
  }
  h4{
    margin-bottom: 3px;
    font-size: 1vw;
    white-space: nowrap;
  }
}
.check-up{
  width: 4vw;
  height: fit-content;
  background-color: #90c7e0;
  border-radius: 100%;
  padding: 4px;
}
.list-box{
  background-color: rgb(215, 241, 247);
  border-radius: 5px;
  padding: 10px;
  color: #095D7E;
}
.btns{
  width: auto;
  height: auto;
  border:2px solid transparent;
  font-size: 1vw;
  font-weight: 500;
  background-color: whitesmoke;
  border-radius: 20px;
  padding: 5px 9px ;
  display: inline;
  align-items: center;
 
&:hover{
  background-color:transparent;
  
}
}
.btns-approve {
  color: rgb(16, 172, 16);
  &:hover{
    border: 1.5px solid rgb(93, 181, 93);
    color: rgb(16, 172, 16);
  }
  
}

.btns-reject {
  border:2px solid rgb(195, 12, 12);
  /* box-shadow:  0 2px 10px 0 rgb(178, 121, 121); */
  color: rgb(195, 12, 12);
  color:"white";
  &:hover{
    box-shadow:  0 2px 10px 0 rgb(178, 121, 121);
    /* border:2px solid rgb(195, 12, 12); */
    
  }
  
}
.btns-reschedule{
 
  color: #095D7E;
  box-shadow:  0 2px 10px 0 #69a3ba;
  color:"white";
  &:hover{
    border:2px solid #095D7E;
    
  }

}
.separator {
  width: 2.5px;
  height:5vw; 
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  background-color:#4b869dee; 
  border-radius: 5px;
}
.cal-icon{
  width: auto;
  margin-right: 2px;
  height: 18px;
  /* margin-bottom: 4px; */
}
.user-icon{
  /* color:#001219; */
  width: auto;
  height: 17px;
  margin-right: 5px;
  /* margin-bottom: 4px; */
}
.status{
  color: #45a049;
  margin: 20px 20px;
}
.container-profile{

padding-top: 100px;
text-align: center;
/* color: #0A9396; */
color: #005F73;
}
.icon-msg{
  color: #005F73;
}
.icon-ph{
  background-color: #005F73;
  color: white;
  border-radius: 100%;
  padding: 1.5px;
}
.btn-signout{
  background-color: #0A9396;
  color: white;
  padding: 5px 16px;
  &:hover{
    background-color: #106e6f;
  }
  
}

.profile-pic-container {
  position: relative;
  display: inline-block;
}

.profile-pic-label {
  display: block;
  cursor: pointer;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.btn-upload {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-upload:hover {
  background-color: #45a049;
}

.profile-pic-container {
  position: relative;
  display: inline-block;
}

.profile-pic-label {
  display: block;
  cursor: pointer;
}

.profile-pic {
  width: 100px;
  height: fit-content;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;

}

.profile-options {
  position: absolute;
  /* top: 100%; */
  right: 0;
  background: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.btn-option {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 15px;
  text-align: left;
  width: 100%;
  list-style-type: none;
}
.link{
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}

.btn-option:hover {
  background-color: #f0f0f0;
}



.submit button{
  width: 100%;
  height: 60px;
  background-color: #6bacaf;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2vw;
  border: none;
  cursor: pointer;
}


.sessionbtn{
  padding: 6px 0px;
  font-size: 1.5vw;
}

/* Styles for medium screens (tablets) */
@media (max-width: 600px) {
  .bookappointmentpage {
    display: block; 
    width: 100%;
    height: 750px; 
    overflow-y: auto;
  }
  .bookappointmentpage .form-content form .firstrow .firstname{
    font-size: 3vw;
  }
  .current-month{
    font-size: 4.2vw;
  }
  .month-year {
    font-size: 4vw;
  }
  .bookappointmentpage .calendar-content .clock-container h2 {
    font-size: 5vw;
    margin-top: 4vw;
  }
  .firstrow button{
    /* width: 100%; */
    font-size: 4vw;
  }
  .bookappointmentpage .form-content h4{
    font-size: 4vw;
  }
  .bookappointmentpage .form-content h3{
    font-size: 4vw;
  }
  .bookappointmentpage .form-content h2 {
    font-size: 5vw;
  }
  .bookappointmentpage .form-content form input[type="submit"]{
    font-size: 4vw;
  }
  .submit-container {
    justify-content: flex-end;
    margin-top: 0;
  }
  .submit button{
    font-size: 5vw;
  }
  .sessionbtn{
    padding: 6px 0px;
    font-size: 4vw;
  }

}

@media (min-width: 600px){
  .days-of-week {
    font-size: 1.2vw;

  }
  .calendar-days{
    font-size: 1.2vw;
  
  }
 
}



.wrapper{
  display: flex;
  width: 100vw;
  height: 100vh;
}
.left-side{
  width: 30%;
  height: 90%;
  /* background-color: #005F73; */
  border: 3px solid #ddd;
  border-radius: 5px;
  
}

.leftside-list {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 1vw; */
  padding: 3vh 2vw 3vh 2vw;
  font-size: 2vw;
  font-weight: 500;
  border-bottom: 1px solid #7e7c7b65;
  cursor: pointer;
  &:hover{
    background-color: #006073ab;
    color: white;
    /* border-radius: 12px; */
  }
}
.left-list {
  height: 100%;
  padding: 0px;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  
}

.right-side{
  width: 100%;
  height: 90%;
  /* overflow-y: auto; */
  /* padding: 10px; */
  /* height: 100%; */
  
}
/* .leftside-list.selected{
  color: #0A9396;
} */

.switch input:checked + .slider:after {
  content: '';
  /* position: absolute; */
  width: 100%;
  height: 100%;
  top: -50%;
  left: -50%;
  background: #74c0c3c9;
  border-radius: 50%;
  animation: ripple 0.6s ease;
}

.bookbtn{
  &:hover{
    
    background-color: #005f73;
  }

}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-button{
  padding: 6px;
  background-color:#005f73;
  border-radius: 19px;
  &:hover{
    background-color: #005F73;
  }
}
.rolebtn{
  border:2px solid #005f73;
  background-color: transparent;
  color: #005f73;
  font-size: 19px;
  font-weight: 500;
  border-radius: 19px;
  margin-bottom: 12px;
  &:hover{
    background-color: #287d8f;
    color: white;
  }
}