.account-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 6px;
}

.right-container {
    width: 100%;
    height: 44.4%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgb(144, 141, 141);
    p{
        font-size: 17px;
        font-weight: 500;
    }
    
}



.acc-btn {
    background-color: #0A9396;
    color: white;
    font-weight: 500;
    padding: 7px 10px;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap; /* Prevents button text from wrapping */
    /* margin-bottom: 14px; */
    
    &:hover{
        background-color: #005F73;
    }
}

.setup-cont{
    display: flex;
    padding: 10px;
    width: 100%;
    /* justify-content: center; */
    li{
        white-space: nowrap;
        font-weight: 500;
        /* width: 20px; */
    }
}
.inp{
  border-radius: 7px;
  border: 2px solid #0A9396;
  max-width: 600px;
  height: auto;
  padding: 3px;
  /* margin: 7px; */
}
.list{
    white-space: nowrap;
        font-weight: 500;
        /* width: 100px; */
        /* padding: 7px; */
        list-style: none;

}
.list-cont{
  gap: 10px;
}
.input-fields{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputs{
    border-radius: 7px;
  border: 2px solid #0A9396;
  width: 40px;
  padding: 5px 0;
  

}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50vw;
    /* text-align: center; */
  }
  .popup-contents{
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50vw;
  }
  .textarea{
    border: 2px solid #0A9396;
    border-radius: 5px;
    width: 400px;
    height: 50px;

  }

  .data-container{
    padding: 10px;
    width: 100%;
    height: 100%;
  }
  .data-btn{
    background-color: #0a9496a9;
    color: white;
    font-weight: 500;
    padding: 18px 10px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap; 
    width:100%;
    
    
    &:hover{
        background-color: #0A9396;
    }

  }

  .data-btn.active-tab {
    background-color:#0a898b;
  }
  

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
   td {
    border-bottom: 3px solid #ddddddbd;
    
    padding: 8px;
    text-align: left;
  }
  
  th {
    padding: 8px;
    /* background-color: #f2f2f2; */
  }

  .search-input {
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #0A9396;
    width: 200px;
    height: 30px;
    margin-right: 15px;
  }
  
  .full-page-view {
    position: fixed;
    top: 80px;
    /* left: 0; */
    width: 76%;
    height: 90%;
    background-color: white;
    padding: 20px;
    overflow-y: auto;
    z-index: 1000;
  }
  

 
  .table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container th,
  .table-container td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }

  .edit-btn{
    background-color: #0a949675;
    color: rgb(12, 11, 11);
    font-weight: bold;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap; /* Prevents button text from wrapping */
    /* margin-bottom: 14px; */
    
    &:hover{
        color: aliceblue;
        background-color: #006073d2;
    }

  }
  .delete-btn{
    background-color: #0a949675;
    color: rgb(12, 11, 11);
    font-weight: bold;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap; /* Prevents button text from wrapping */
    /* margin-bottom: 14px; */

    
    &:hover{
        color: aliceblue;
        background-color: #006073d2;
    }

  }

.dosage-details{
  color: #20b4b6;
  font-weight: 500;
  border-bottom: 3px solid #ddd;
  li{
    list-style: none;
    color: rgba(128, 128, 128, 0.718);
  }

}
  
.medicine{
  td{
    color: black;
    font-weight: 500;
    border-bottom: none;
  }
  th{
    border-bottom: 3px solid #ddd;
  }
}
  
.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-popup-content {
  background-color: rgb(251, 247, 247);
  padding: 20px;
  border-radius: 5px;
  width: 60%;
  padding-bottom: 50px;
  /* max-width: 500px; */
}

.temp-inp{
  border-radius: 10px;
  border: 2px solid #0A9396;
  max-width: 600px;
  padding: 2px 7px;
  
}
.btnselected{
  background-color: #0a9396 !important;
  color: #FFFFFF;
}
.btnunselected {
  color: grey; 
}

.button-container button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10%;
  font-size: 1.2vw;
  background-color: #f3f3f3;
  border-radius: 6px;
  overflow:hidden;
  border: none;
  transition-duration: 0.5s;
  &:hover{
    background-color: #0a9396;
    color: white;
  }
}

.button-container input{
  height: 160%;
  width: 15%;
  padding: 0.3vw 1vw;
  margin: 0;
  border-radius: 10px;
  overflow:hidden;
  border: 1px solid #0a9396;
  transition-duration: 0.5s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.button-container select{
  height: 100%;
  width: 20%;
  padding:0.2vw 1vw;
  margin: 0;
  font-size: 1.2vw;
  font-weight: 600;
  border-radius: 5px;
  overflow:hidden;
  background-color: #f3f3f3;
  border: 1.5px solid #0a9396;
  transition-duration: 0.5s;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  width: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}

.textarea-input {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  border: 2px solid #0a9396 ;
  border-radius: 20px;
  padding: 6px;
}



.medlist {
  display: flex;
  flex-direction: row; /* Ensure items are displayed in a row */
  
  gap: 10px; /* Add some spacing between items */
}

.meds {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.meds.selected {
  background-color: #0a9396 ; 
  color: white;
}
.cliniclist{
  font-size: 24px;
  display: flex;
  cursor: pointer;
  color: grey;
  &:hover{
    color: #005F73;
  }
}
.switchclinicA{
  font-weight: 600;
  color: black;

  /* background-color: #0a9396; */
}
.search-container {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-item:hover {
  background-color: #f5f5f5;
}

.selected-clinic-info {
  background: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.selected-clinic-info p {
  margin: 0;
  padding: 2px 0;
}

.search-container {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-item:hover {
  background-color: #f5f5f5;
}

.selected-clinic-info {
  background: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.selected-clinic-info p {
  margin: 0;
  padding: 2px 0;
}