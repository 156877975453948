.icon {
  vertical-align: middle;
  cursor: pointer;
  line-height: 1px;
  font-size: 2vw;
}
.header-right {
  padding-right: 2vw;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color:#d50000;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
  font-size:4vmin;
}

.grid-container {
  display: grid;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  grid-template-columns: 0.8fr 1.5fr 1fr;
  grid-template-rows: 0fr 3fr;
  grid-template-areas:
    'header header header'
    'main1 main2 main3';
}

/* Main  */  

/* Content1  */  
.main-container1 {
  grid-area: main1;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-self:flex-end;
  justify-content:flex-start;
  align-items: center;
  overflow: hidden;
  padding: 1.2vw;
  background-color: #ffffff;
}
.input-wrapper {
  width: 100%;
  height: 6vh;
  border: none;
  overflow: hidden;
  border-radius: 10px;
  padding: 0 15px;
  /* box-shadow: 0 5px 10px #ddd; */
  background-color: #eaf0ff;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1rem;
  width: 100%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: #00000075;
}

.results-list {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.datefilter{
  position: relative;
  display: flex;
  direction: row;
  width: 100%;
  justify-content:space-between;
  padding: 0.5vw 1vw;
  align-items: center;
}
.datefilter .icons{
  right: 1vw;
}
.icons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}
.calendar{
  position: absolute;
  top: 4vh;
  left: 4vw;
  z-index: 1;
}

.dropdown-content{
  position: absolute;
  top: 4vh;
  left: 8vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  border-radius: 5px;
  width: 60%;
  height: auto;
  overflow: hidden;
  background-color: #74c0c3;
}

.dropdown-content .option{
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1.3vw; */
  font-weight: 600;
  width: 100%;
  cursor:pointer;
}

.option:hover{
  background-color: #256e6b;
  
  color: white;
}


.queue{
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items:center;
  justify-items:flex-start;
  justify-content:flex-start;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border-width:2px;
  border-style:solid;
  border-color: #cde5e8;
  overflow: hidden;
  padding-bottom: 10px;
}

.queue .list{
  display : flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.list::-webkit-scrollbar {
  display: none;  
}

.list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.3vw 1vw;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.list-item .name{
  width: 80%;
  overflow: hidden;
  font-size: 1.2vw;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  padding-left: 10px;
}
div.name:hover {
  overflow: visible;
}
.list-itemactive{
  background-color: #cde5e8;
}

.list-container :hover{
  background-color: #cde5e8;
}

.indicator {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
}
.active{
  background-color: #74c398 !important;
}
.time{
  width: 10%;
  font-size: 2vw;
  color: #ffd11a;
}
.sliding-switch {
  position: relative;
  width: 90%;
  height: 75%;
  background-color: #74c0c3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slider {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  width: 50%;
  height: 100%;
  border-radius: 20px;
  font-weight:600;
  background: #383C44;
  background-color: #e9f6fa;
  border: 2px solid #74c0c3;
  transition: transform 0.4s ease;
}

.sliding-switch.on .slider {
  transform: translateX(100%);
}

.sliding-switch.off .slider {
  transform: translateX(0);
}

.label {
  flex: 1;
  text-align: center;
  font-size: 1vw;
  font-weight: 400;
  color: white;
  transition: opacity 0.3s ease;
}

.sliding-switch.on .label.off {
  opacity: 0;
}

.sliding-switch.off .label.on {
  opacity: 0;
}

/* if no selected */
.noselected{
  grid-area: main2,main3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Content2  */  
.main-container2 {
  grid-area: main2;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-self:flex-start;
  justify-content:flex-start;
  align-items:flex-start;
  overflow: hidden;
  padding:1.2vw 0px;
  background-color: #ffffff;

}

.head{
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-end;
}



.record{
  display: flex;
  flex-direction: column;
  /* align-items:center; */
  justify-content:center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border-width:2px;
  border-style:solid;
  border-color: #cde5e8;
  background-color: #f7f9fc;
}

.slider-item {
display: flex;
align-items: center;
justify-content: center;
height: 100%;
}
.info{
  width: -webkit-fill-available;
  height: 100%;
  flex-grow: 1;
}
.buttons{
  display: flex;
  width: 100%;
  align-self: center;
  align-items: center;
  justify-items: center;
  justify-content:flex-end;
  margin-top: 2vh;
}
.slick-list{
  height: -webkit-fill-available;
}
.hoverbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 6vh;
  padding:0px 12px 0px 12px;
  border: solid 2px;
  border-color: #095d7e;
  border-radius: 10px;
  color:  #095d7e;
  font-size: clamp(10px, 1.5vw, 20px);
  transition-duration: 0.4s;
}
.hoverbutton:hover svg {
  transform: translateX(10px);
  color: var(--hovered-color);
  transition: 0.3s ease-out;
}
.hoverbutton:hover {
  border-color: #74c0c3;
  background-color: #74c0c3;
  color: white;
}
/* Content3  */  
.main-container3 {
  grid-area: main3;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content:flex-start;
  /* align-items: flex-end; */
  overflow: hidden;
  padding: 2vh 0.5vw;
  background-color: #ffffff;
}
.savepatient{
  font-size: 1.5vw;
  white-space: nowrap;
  font-weight: 500;
 
  margin-top: auto;
  margin-bottom: auto;
}

.doctorstatus{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 5vh;
  padding-right: 3vw;
  font-size : 1.5vw;
  font-weight: 400;
  color:#000000;
  letter-spacing: -0.01em;
}

.heart-container {
  --heart-color: #74c0c3;
  position: relative;
  width: 4vh;
  height: 4vh;
  margin: 0 1vw;
  transition: .3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
        .heart-container .svg-filled {
  fill: var(--heart-color);
  position: absolute;
  
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate .5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked~.svg-container .svg-filled {
  display: block
}

.heart-container .checkbox:checked~.svg-container .svg-celebrate {
  display: block
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}

.doctorstatus .online-indicator {
  display: inline-block;
  width: 1vw;
  height: 1vw;
  margin-top: 1.5vh;
  margin-left: 0.5vw;
  margin-right: 0.5vw; 
  background-color: #0fcc45;
  border-radius: 50%;
  position: relative;
}
.doctorstatus span.blink {
  display: block;
  width: 1vw;
  height: 1vw;
  background-color: #0fcc45;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  100% { transform: scale(2, 2); 
          opacity: 0;
        }
}
.blocks{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    row-gap: 1vh;
    margin-bottom: 1vh;
    padding-right: 1vw;
    padding-left: 0.5vw;
}
.blocks::-webkit-scrollbar {
  width: 20px;  
}
.blocks::-webkit-scrollbar-thumb {
  background-color: #74c0c3;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
  border: 3px solid #cde5e8;
}
.blocks::-webkit-scrollbar-track {
  background-color: #cde5e8;
  border-radius: 100px;
}

.CompactCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 10px;
  border-radius: 8px;
  border-width:2px;
  border-style:solid;
  border-color: #74c0c3;
  width: 100%;
  /* min-height: 55%; */
  height: max-content;
  max-height: 100vh;
  cursor: pointer;

}
.CompactCard:hover {
  box-shadow:#74c0c3 2px 2px 20px;
}

.CompactCard .heading {
  height: 10%;
  font-size: 1.2vw;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
}


.ExpandedCard {
  position: absolute;
  width: 60%;
  height: 70vh;
  z-index: 9;
  left: 13rem;
  top: 8rem;
  z-index:100;
  border: none;
  border-radius: 1rem;
  /* border: #74c0c3 2px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  padding: 2vh 1vw;
  overflow: hidden;
  background-color: #fff;
  box-shadow: #e9f6fa 0px 8px 240px, rgba(17, 17, 26, 0.5) 0px 16px 240px, #e9f6fa 0px 24px 240px;
}
.ExpandedCard .card-title{
  display: flex;
  width: 100%;
  height: 12%;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  overflow: hidden;
}

.ExpandedCard .card-title h4{
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.8vw;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.ExpandedCard .buttons{
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content:space-between;
}

.ExpandedCard .btn {
  font-size: 1.2vw;
  font-weight: 600;
  letter-spacing: -0.01em;
  border: 2px solid #095D7E;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  color: #095D7E;
}

.ExpandedCard .btn:hover {
  background-color: #095D7E;
  color: white;
}

.ExpandedCard .Chatbox{
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 10px;
  height: 70%;
  width: 100%;
  font-size: 1vw;
  color: #4A4A4A;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.Chatbox::-webkit-scrollbar {
  display: none;  
}


.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-inner > .card_icon {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

/* End Main  */


/* Medium <= 992px */
  
@media screen and (max-width: 992px) {

  #sidebar {
    display: none;
  }

  .menu {
    width: 8vw;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 0fr 3fr;
    grid-template-areas:
      'header header header'
      'main1 main2 main3';
  }
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .menu {
    width: 8vw;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}








.no-records {
  text-align: center;
  color: #777;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border-width:2px;
  border-style:solid;
  border-color: #cde5e8;
  background-color: #f7f9fc;
}

.upload-section {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: 20px;
  /* justify-content: space-between; */
  gap: 10px;
}




.selected-files {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
overflow-x: auto;
max-width: 100%;
/* scrollbar-width: thin; */
/* scrollbar-color: #b32f2f #f1f1f1; */
/* justify-content: left; */

}

.selected-files::-webkit-scrollbar {
  /* width: auto;   */
  height: 7px;

}
.selected-files::-webkit-scrollbar-track {
  background: #cde5e8; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}
.selected-files::-webkit-scrollbar-thumb {
  background-color: #74c0c3; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #cde5e8; 
}
.selected-files::-webkit-scrollbar-thumb:hover {
  background-color: #518c8f; /* Darker color on hover */
}


.file-item {
  display: flex;
  align-items: center;

  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 4px;
}
.file-item span{
  white-space: nowrap;
}

/* .file-item button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.file-item button:hover {
  background-color: #c82333;
} */

.remove-btn{
width: 21px;
height: auto;
margin-left:10px ;
color: #dc3545;
&:hover{
  color: #c82333;
}
}



/* Custom Modal Style */
.ReactModal__Overlay {
/* background-color: #0056b3;  */
display: flex;
justify-content: center;
align-items: center;
}

.ReactModal__Content {
position: relative;
top: auto;
left: auto;
right: auto;
bottom: auto;
border: 1px solid #ccc;
background: white;
overflow: auto;
border-radius: 10px;
outline: none;
padding: 20px 20px;
width: 40%;
height: 75%; 
max-width: 90%;
/* max-height: 90%; */
box-shadow: 0 2px 29px 0 rgba(116, 114, 114, 0.797);
}

.ReactModal__Content h2 {
margin-top: 0;
margin-bottom: 20px;
font-size: 24px; /* Customize the font size of the heading */
}

.ReactModal__Content form {
display: flex;
flex-direction: column;
}

.ReactModal__Content form label {
margin-bottom: 10px;
font-weight: bold;
}

.ReactModal__Content form .custom-calendar {
margin-bottom: 25px;
}

.ReactModal__Content form button {
margin: 10px 0;
/* padding: 10px 20px; */
border: none;
cursor: pointer;
background-color: #007BFF; /* Primary button color */
height: 40px;
color: white;
font-weight: bold;
border-radius: 5px;
}

.ReactModal__Content form button[type="button"] {
background-color: #6bacaf; /* Secondary button color */
}

.ReactModal__Content form button:hover {
background-color: #0056b3; /* Darker shade on hover for primary button */

}

.ReactModal__Content form button[type="button"]:hover {
background-color: #005F73; /* Darker shade on hover for secondary button */
}

.ReactModal__Content .time-slot-buttons {
display: flex;
justify-content: space-between;
}

.ReactModal__Content .time-slot-buttons button {
flex: 1;
margin: 5px;
background-color: #6bacaf;
color: white;
}

.ReactModal__Content .time-slot-buttons button.active {
background-color: #6bacaf;
color: white;
}

.ReactModal__Content .time-slot-buttons button:hover {
background-color: #0056b3;
color: white;
}



.recep-btn{
color:whitesmoke;
background-color: #0A9396;
font-size: 16px;
font-weight: 500;
width: 100%;
padding: 9px;
&:hover{
  background-color: #005F73;
}

}

.heading-report{
display: flex;
justify-content: space-between;
padding-left: 5px;
text-align: center;
gap: 5px;
margin-bottom: 5px;
th{
background-color: #83d9da;
width: 100%;
padding: 7px 0px;
/* margin: 2px; */
}
}

.days-of-week{
display: flex;
justify-content: space-between;
padding: 1.8vw;

}

/* Custom scrollbar for the modal */
.ReactModal__Content::-webkit-scrollbar {
width: 8px; /* Width of the scrollbar */
height: 100%;
}

.ReactModal__Content::-webkit-scrollbar-track {
background: #f1f1f1; /* Background of the scrollbar track */
border-radius: 50px;
margin: 2px;
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  background-color: #74c0c3;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
  /* border: 3px solid #cde5e8; */
}

.ReactModal__Content::-webkit-scrollbar-thumb:hover {
background-color: #0A6366; /* Color on hover */
}

.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.modal-content {
background-color: white;
padding: 20px;
border-radius: 8px;
width: 550px;
max-width: 90%;
max-height: 60vh;
overflow-y: auto;
border-radius: 10px;
}


/* Webkit browsers (Chrome, Safari) */
.modal-content::-webkit-scrollbar {
width: 12px; /* Adjust width of scrollbar */
}

.modal-content::-webkit-scrollbar-thumb {
background: #74c0c3; /* Color of the scrollbar thumb */
border-radius:10px; /* Round the corners of the scrollbar thumb */
}

.modal-content::-webkit-scrollbar-track {
background: #e0f4f3; /* Background color of the scrollbar track */
border-radius: 0px 10px 10px 0; /* Round the corners of the scrollbar track */
}



.input-rows {
display: flex;
justify-content: space-between;
/* margin-bottom: 10px; */
}
.input{
width: 100%;
background-color: #d0e6f5;
text-align: center;
margin-bottom: 5px;
}


button {
padding: 8px 12px;
background-color: #005f73;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

button:hover {
background-color: #0a9396;
}


.btns-report{
margin: 4px;
font-weight: 500;
color: white;
/* background-color:#83d9da ; */
background-color:#0a9496ce ;
&:hover{
  background-color: #0A9396;
}

}



/* .calendar */
.react-datepicker__header {
background-color: #f8f8f8;
border-bottom: 1px solid #e8e8e8;
padding: 10px;
}

.custom-header {
display: flex;
justify-content: space-between;
align-items: center;

}

.custom-header button {
background: none;
border: none;
cursor: pointer;
font-size: 18px;
}


.custom-calendar {
background: white;
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
width: 240px;
}

.calendar-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;
background: #f8f8f8;
border-bottom: 1px solid #e8e8e8;
cursor: pointer;
}

.month-grid {
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 5px;
padding: 10px;
}

.month-item {
text-align: center;
padding: 5px;
cursor: pointer;
}

.month-item:hover {
background-color: #e6e6e6;
}

.month-item.selected {
background-color: #216ba5;
color: white;
}

.year-list {
max-height: 200px;
overflow-y: auto;
}

.year-list div {
padding: 5px 10px;
cursor: pointer;
}

.year-list div:hover {
background-color: #e6e6e6;
}


.custom-calendar {
background: white;
border: 1px solid #ccc;
border-radius: 10px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
width: 280px; 
height: 353px;

position: fixed;
/* z-index: 1000; */
}

.calendar-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;
background: #f8f8f8;
border-bottom: 1px solid #e8e8e8;
cursor: pointer;
}

.month-grid, .day-grid {
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 5px;
padding: 10px;
}

.day-grid {
grid-template-columns: repeat(7, 1fr);
}

.month-item, .day-item {
text-align: center;
padding: 5px;
cursor: pointer;
}

.month-item:hover, .day-item:hover {
background-color: #e6e6e6;
}

.month-item.selected, .day-item.selected {
background-color: #216ba5;
color: white;
}

.year-list {
max-height: 300px;
overflow-y: auto;
}

.year-list div {
padding: 5px 10px;
cursor: pointer;
}

.year-list div:hover {
background-color: #e6e6e6;
}

.weekdays {
display: grid;
grid-template-columns: repeat(7, 1fr);
gap: 5px;
padding: 5px 10px;
background-color: #f0f0f0;
font-weight: bold;
}

.weekday {
text-align: center;
}

.day-item.empty {
visibility: hidden;
}

.back-to-months {
width: 100%;
padding: 10px;
background-color: #f0f0f0;
border: none;
border-top: 1px solid #ccc;
cursor: pointer;
}

.back-to-months:hover {
background-color: #f9f6f6;
}

.today-button{
background-color: rgba(128, 128, 128, 0.168);
text-align: center;
border-radius: 0 0 7px 7px;
cursor: pointer;
font-weight: 500;
color: #000000ae;
}







.file-upload-container {
display: flex;
width: 100%;
align-items: center;
justify-content: center;
gap: 16px;
padding: 5px;
/* background-color: #f3f4f6; */
border-radius: 8px; /* Rounded corners */

}

.file-input {
/* flex: 1; */
width: 100%;
padding: 8px 12px;
border: 1px solid #d1d5db; /* Gray border */
border-radius: 4px;
background-color: #ffffff; /* White background */
cursor: pointer;
}

.upload-button-meow {
margin-top: 0;
padding: 8px 16px;
background-color: #3b82f6; /* Blue background */
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s ease-in-out;
}

.upload-button:hover {
background-color: #2563eb; /* Darker blue on hover */
}



.top-patient-info{
display: flex;
align-items: center;
/* padding: 4px; */
gap: 20px;
margin-bottom: 1vh;
font-size: 1rem;
padding-left: 8px;

/* border-radius: 10px; */
}
.top-patient-info-vars{
color: #0A6366; 
font-weight: 500;
}







/* Ensure PDF viewer takes up the full width and proper height */
.pdf-viewer {
width: 100%;
height: 64vh;
border-radius: 9px;
border-width:2px;
  border-style:solid;
  border-color: #cde5e8;

}

/* Image viewer for non-PDF files */
.image-viewer {
width: 100%;
height: 64vh;
object-fit: contain; 
}

/* File upload and button container */
.patientrecord-button{
background-color: #f3f4f6;
padding: 1%;
border-radius: 0 0 7px 7px;
display: flex;
justify-content: center;
align-items: center;
}

/* Style for file upload container */
.file-upload-container {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}

/* Ensure buttons are responsive */
.upload-button-meow {
flex: 1;
margin: 0 10px;
padding: 10px;
border-radius: 5px;
background-color: #007bff;
color: white;
border: none;
cursor: pointer;
text-align: center;
}




/* Responsive styles for smaller screens */
@media (max-width: 768px) {

.pdf-viewer {
height: 55vh;
}

.file-upload-container {
  /* flex-direction: column; */
  align-items: stretch;
}


}


@media (max-width: 1024px){

.pdf-viewer {
  height: 70vh;
  }
  

}
@media (min-width: 900px){
  .ReactModal__Content {
    padding: 20px 40px;
    
  
  }
}

.list-item .statusname{
  font-size: 1vw;
  font-weight: 500;
  color: grey;
  
}
.fifiltericon{
  font-size: 2vw;
  cursor: pointer;
  align-items: center;
}
.todaydate{
  font-size: 1.7vw;
  font-weight: 600;
}

.vitaldropdown{
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 1vw;
  font-weight: 600;
  

}
.vitalselectedunit{
 height: 42px;
    padding: 10px;
    cursor: pointer;
   border:1px solid #0A9396 ;
    border-radius: 9px;
   font-size: 1vw;
   font-weight: 600;
  
}
.queuelist{
  font-size: 1.2vw;
}
@media screen and (max-width: 600px){
  .grid-container{
    display: block;
  }
  .main-container2{
    height:670px;
  }
  .main-container1{
    height: 88vh;
  }
  .list-item .name{
    font-size: 4.4vw;
  }
  .list-item .statusname{
    font-size: 3vw;
    font-weight: 500;
    color: grey;
    
  }
  
.indicator{
  width: 4vw;
  height: 2.7vh;
}
.time {
  font-size: 5vw;
}
.label{
  font-size: 5vw;
}
.slider{
  font-size: 5vw;
}
.fifiltericon{
  font-size: 7vw;
}
.todaydate{
  font-size: 5vw;
}
.dropdown-content{
  width: 40%;
  left: 55vw;
}
.calendar{
  left: 10vw;
}
.savepatient{
  font-size: 4vw;
  
}
.CompactCard .heading{
  font-size: 5vw;
}
.ReactModal__Content {
  width: 100%;
  

}
.vitaldropdown{
  font-size: 3vw;
}
.vitalselectedunit{
  font-size: 3vw;
}
.queuelist{
  font-size: 4vw;
}
.main-container1{
  height: 84vh;
}


}


