.todo-form {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: #fff;
  transition: all 0.3s ease; 
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  width: fit-content;
  height: auto;
}
.close{
  width: 25px;
  height: fit-content;
  color: rgb(12, 69, 83);
  /* color: red; */
}
.title-icon{
  width: auto;
  height: 35px;
  color: rgb(164, 162, 162);
  /* color: #5b92a8; */
}
.title-header{
  color: whitesmoke;
}
.todo-header{
  /* background-color: #c7c5c5; */
  background-color: #286b81;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}
.imgenlarge{
 /* justify-content: center; */
 margin: 5px;
}
.clocks{
 color: grey ;
 width: 22px;
 height: fit-content;
 margin-top: 14px;
}
.timer-input{
  gap: 9px;
}

.timer{
  color: grey ;
 width: 30px;
 height: fit-content;
 margin-top: 10px;

}

.date-div{
  margin-left: 40px;
}

.date-time {
  display: flex;
  
  gap: 10.5px;
}
.repeat-option{
  width: 260px;
  margin-left: 45px;
  
}



.notification-timing{
  width: 300px;
}
.location{
  margin-left: 13.5px;
  gap: 3px;
}
.location-icon{
  color: grey ;
 width: 28px;
 height: fit-content;
 margin-top: 5px;
 
}
.description{
  color: grey ;
 width: 26px;
 height: fit-content;
 margin-top: 2%;
}

.toggle-all-day {
  display: flex;
  align-items: center;
  gap: 5px;
}

textarea {
  height: 100px;
}

.form-buttons {
  display: flex;
  gap: 10px;
  /* background-color: rgba(214, 243, 250, 0.755); */
  background-color: #e7e4e462;
  padding: 7px;
  border-radius: 0 0 5px 5px;
  justify-content: space-between;
}

button {
  padding: 2px 5px 2px 5px;
  background-color: #007bff;
  color: white;
  border-radius: 3.5px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.todo-form-fullscreen {
     width: 60vw;
    height: auto;
    
    background-color: white;
   
  }
  .save{
   display: flex;
   color: #fff;
   padding: 1.1%;
   background-color: #1c77c2fd;
  }
  

  



  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 35px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .sliders:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .sliders {
    background-color: #2196F3;
  }
  
  input:focus + .sliders {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .sliders:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .sliders.round {
    border-radius: 34px;
  }
  
  .sliders.round:before {
    border-radius: 50%;
  }
  

  @media only screen
and (min-device-width: 490px) 
  and (max-device-width: 900px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .todo-form {
      width: 80vw;
      height: auto;
    }
    .todo-form-fullscreen {
      width: 80vw;
      height: auto;
      
      background-color: white;
     
    }

  }

